<template>
  <div class="mainBox">
    <div class="header_common_box">
      <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div class="agreementTextBox">
      <div v-html="agreementText"></div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils.js";

export default {
  components: {
    vHeader,
  },

  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
    this.onLoad();
  },
  data(){
    return{
      title: this.$route.meta.title,
      agreementText: '',
    }
  },
  methods: {
    async onLoad() {
      const res = await this.$HTTP.post(this, 'API_USER_AGREEMENT_ONE', { 'agreementTitle': '用户协议'}, {}, true);
      this.agreementText = this.htmlDecodeByRegExp(res.data.agreementContents);
    },
    htmlDecodeByRegExp(str) {
      var s = '';
      if (str.length === 0) {
        return '';
      }
      s = str.replace(/&amp;/g, '&');
      s = s.replace(/&lt;/g, '<');
      s = s.replace(/&gt;/g, '>');
      s = s.replace(/&nbsp;/g, ' ');
      s = s.replace(/&#39;/g, '\'');
      s = s.replace(/&quot;/g, '\"');
      return s;
    },
  }
}
</script>

<style lang="less">
.mainBox{
  .agreementTextBox{
    width: 93%;
    height: 100%;
    font-size: 19px;
    padding: 0px 15px;
  }
}
</style>